import ErrorMessageAreaMolecules from "@/components/molecules/catalog/ErrorMessageAreaMolecules.vue"; // @ is an alias to /src
import LongInputMolecules from "@/components/molecules/catalog/LongInputMolecules.vue"; // @ is an alias to /src
import PolicyProtectionMolecules from "@/components/molecules/catalog/PolicyProtectionMolecules.vue"; // @ is an alias to /srcPolicy
import SendMailMolecules from "@/components/molecules/lead/SendMailMolecules.vue"; // @ is an alias to /src
import { ApiUrlEnv } from "@/env/ApiUrlEnv";
import { SiteUrlEnv } from "@/env/SiteUrlEnv";
import {
  DataType,
  InputParam,
  RequestParam,
} from "@/interface/lead/LeadInputInterFaces";
import { EstimateSiteUrlGenerater } from "@/services/lead/EstimateSiteUrlGenerater";

import { Messages } from "@/utils/message/LeadMessages";
import { LeadInternalCodeEnum } from "@/utils/message/num/LeadInternalCodeEnum";
import { Validater } from "@/utils/validater/LeadValidaters";
import axios from "axios";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "InputMolecules",
  components: {
    LongInputMolecules,
    PolicyProtectionMolecules,
    ErrorMessageAreaMolecules,
    SendMailMolecules,
  },
  data(): DataType {
    return {
      inputParam: {
        estimateYear: "",
        estimateMonth: "",
        estimateDay: "",
        birthYear: "",
        birthMonth: "",
        birthDay: "",
        contractAge: "",
        sex: "",
        lifeActivator: "",
        insurance: "",
        term: "",
        lifePrice: "",
        treatmentActivator: "",
        tmtTerm: "",
        treatmentPrice: "",
        medical3Activator: "",
        medical3Admission: "",
        medical3Payment: "",
        medical3Course: "",
        medical3Price: "",
        disability3Activator: "",
        disability3Benefit: "",
        disability3Term: "",
        disability3Billable: "",
        disability3Option: "",
        disability3Return: "",
        disability3Price: "",
        cancerActivator: "",
        cancerBenefit: "",
        cancerCourse: "",
        cancerPrice: "",
        dementiaActivator: "",
        dementiaCourse: "",
        dementiaBenefit: "",
        dementiaPrice: "",
        estimateSiteUrl: "",
        totalPrice: "",
        wishMail: "1",
        mailSmsRecipient: "",
        siteCatalystId: "",
        karteUserId: "",
      },
      accordionStatus: false,
      validationResultFlg: true,
      estimateSiteUrl: "",
      validationResult: [
        {
          // バリデーション項目
          inputName: "",
          // エラーコード
          internalCode: "",
          // バリデーション結果
          isSuccess: true,
          // バリデーションメッセージ
          message: "",
        },
      ],
      validationMessages: [],
      errorTagList: [],
      internalCodeList: [],
      karteUserId: "",
    };
  },
  methods: {
    // VuexからMutationsを呼び出す
    ...mapMutations([
      "setEstimateSiteUrl",
      "setLoadingStatus",
      "setContractKey",
      "setRequestParam",
    ]),
    ...mapGetters(["getInputPageParam", "getReEnterStatus"]),

    /**
     * 完了画面へ遷移します
     * ・バリデーションを行います
     */
    goToComplete() {
      const LeadMessages = new Messages();
      // inputパラメータを生成する
      const param: InputParam = this.generateInputParam();
      // バリデーションを行う
      if (!this.validate(param)) {
        return;
      }

      this.validationResultFlg = true;

      // ローディング画面を表示
      this.setLoadingStatus(true);
      // JSON形式でのリクエストデータを作成する
      const params: RequestParam = this.generateInputParam();
      const header = { "x-api-key": this.planSaveApiKey };

      axios
        .post(this.planSaveApiUrl, params, { headers: header })
        .then((res) => {
          // エラーがあった場合は表示
          if (res.data.internalCode[0] != "N1") {
            this.internalCodeList = res.data.internalCode;
            this.internalCodeList.forEach((result) => {
              this.validationMessages.push(
                LeadMessages.generateMessage(result)
              );
            });
            this.validationResultFlg = false;
            this.setLoadingStatus(false);
            this.moveToTop();
            return;
          }
          //
          this.setEstimateSiteUrl(this.estimateSiteUrl);
          // cookieを削除
          this.$cookies.remove("planSave");
          this.validationResultFlg = true;
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          const pattern =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
          // 見積もり画面のURLをVuexに投げる
          this.setEstimateSiteUrl(this.estimateSiteUrl);
          // 計測用のキーを格納
          this.setContractKey(res.data.mcContactKey);
          // 計測用にリクエストパラメータを格納
          this.setRequestParam(params);
          if (pattern.test(params.mailSmsRecipient)) {
            this.$router.push("/lead/complete_mail");
          } else {
            this.$router.push("/lead/complete_sms");
          }
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          if (!this.validationResultFlg) {
            this.validationResultFlg = true;
            this.validationMessages.push(
              LeadMessages.generateMessage(
                LeadInternalCodeEnum.InternalServerError
              )
            );
          }
        });
    },

    /**
     * バリデーションチェックを行います
     * ・バリデーション結果によって論理値を返却します
     * ・バリデーションに引っ掛かった画面項目を赤塗にします
     * ・エラーメッセージを表示します
     */
    validate(param: InputParam): boolean {
      const LeadMessages = new Messages();
      this.validationResult = Validater.validateLead(param);
      // 送信前のバリデーションチェック
      this.errorTagList.forEach((e) => {
        document.getElementById(e)!.style.backgroundColor = "#fff";
      });
      this.validationMessages = [];
      this.errorTagList = [];
      if (this.validationResult.length != 0) {
        this.validationResult.forEach((result) => {
          document.getElementById(result.inputName)!.style.backgroundColor =
            "#ffd9d9";
          this.errorTagList.push(result.inputName);
          this.validationMessages.push(
            LeadMessages.generateMessage(result.internalCode)
          );
        });
        this.validationResultFlg = false;
        this.moveToTop();
        return false;
      }
      return true;
    },

    /**
     * スクロールイベント
     */
    moveToTop() {
      // 移動速度（1秒で終了）
      const duration = 200;
      // 0.025秒ごとに移動
      const interval = 1;
      // 1回に移動する距離
      const step = -window.scrollY / Math.ceil(duration / interval);
      const timer = setInterval(() => {
        // スクロール位置を移動
        window.scrollBy(0, step);

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },

    /**
     * inputパラメータを生成します
     */
    generateInputParam(): RequestParam {
      const cookie = this.$cookies;
      let svid = "";
      let channelIdentifier = "";
      if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
        channelIdentifier = "010";
      } else {
        channelIdentifier = "001";
      }

      if (
        cookie.get("s_vid") != undefined &&
        cookie.get("s_vid").match(/^[a-zA-Z0-9+.()]+$/)
      ) {
        svid = cookie.get("s_vid");
      }

      // KARTEユーザーID取得
      const karteUserId = <HTMLInputElement>(
        document.getElementById("karte-user-id")
      );
      this.karteUserId = karteUserId.value;
      return {
        method: "CC",
        prePageInfo: "estimate",
        estimateYear: this.inputParam.estimateYear,
        estimateMonth: this.inputParam.estimateMonth,
        estimateDay: this.inputParam.estimateDay,
        birthYear: this.inputParam.birthYear,
        birthMonth: this.inputParam.birthMonth,
        birthDay: this.inputParam.birthDay,
        contractAge: this.inputParam.contractAge,
        sex: this.inputParam.sex,
        lifeActivator: this.inputParam.lifeActivator,
        insurance: this.inputParam.insurance,
        term: this.inputParam.term,
        lifePrice: this.inputParam.lifePrice,
        treatmentActivator: this.inputParam.treatmentActivator,
        tmtTerm: this.inputParam.tmtTerm,
        treatmentPrice: this.inputParam.treatmentPrice,
        medical3Activator: this.inputParam.medical3Activator,
        medical3Admission: this.inputParam.medical3Admission,
        medical3Payment: this.inputParam.medical3Payment,
        medical3Course: this.inputParam.medical3Course,
        medical3Price: this.inputParam.medical3Price,
        disability3Activator: this.inputParam.disability3Activator,
        disability3Benefit: this.inputParam.disability3Benefit,
        disability3Term: this.inputParam.disability3Term,
        disability3Billable: this.inputParam.disability3Billable,
        disability3Option: this.inputParam.disability3Option,
        disability3Return: this.inputParam.disability3Return,
        disability3Price: this.inputParam.disability3Price,
        cancerActivator: this.inputParam.cancerActivator,
        cancerBenefit: this.inputParam.cancerBenefit,
        cancerCourse: this.inputParam.cancerCourse,
        cancerPrice: this.inputParam.cancerPrice,
        dementiaActivator: this.inputParam.dementiaActivator,
        dementiaCourse: this.inputParam.dementiaCourse,
        dementiaBenefit: this.inputParam.dementiaBenefit,
        dementiaPrice: this.inputParam.dementiaPrice,
        estimateSiteUrl: this.inputParam.estimateSiteUrl,
        totalPrice: this.inputParam.totalPrice,
        wishMail: this.inputParam.wishMail,
        mailSmsRecipient: this.inputParam.mailSmsRecipient,
        siteCatalystId: svid,
        karteUserId: this.karteUserId,
        channelIdentifier: channelIdentifier,
      };
    },

    /**
     * 静的サイトからリダイレクトした場合、連携されたURLのチェックを実施する
     * エラーの場合は見積り画面へ遷移する
     *
     */
    validateEstimateSiteUrl() {
      if (!this.inputParam.estimateSiteUrl) {
        window.location.href =
          SiteUrlEnv.estimateSiteBaseUrl + "?internal-code=E100";
      }
      // ライフネットドメインのURLであることをチェック
      const pattern = /^https:\/\/(.*)\.lifenet-seimei.co.jp\/(.*)$/;
      if (!pattern.test(this.inputParam.estimateSiteUrl)) {
        window.location.href =
          SiteUrlEnv.estimateSiteBaseUrl + "?internal-code=E100";
      }
    },

    /**
     * 静的サイトからリダイレクトした場合、cookieから値を取得します
     */
    setInputParamFromCookie() {
      const cookie = this.$cookies;
      if (cookie.get("planSave") != undefined) {
        const planSave = new Array(cookie.get("planSave"));
        this.inputParam.birthYear = planSave[0].birthYear;
        this.inputParam.birthMonth = planSave[0].birthMonth;
        this.inputParam.birthDay = planSave[0].birthDay;
        this.inputParam.estimateYear = planSave[0].estimateYear;
        this.inputParam.estimateMonth = planSave[0].estimateMonth;
        this.inputParam.estimateDay = planSave[0].estimateDay;
        this.inputParam.contractAge = planSave[0].age;
        this.inputParam.sex = planSave[0].sex;
        this.inputParam.lifeActivator = planSave[0].lifeActivator;
        this.inputParam.insurance = planSave[0].insurance;
        this.inputParam.term = planSave[0].term;
        this.inputParam.lifePrice = planSave[0].lifeprice;
        this.inputParam.medical3Activator = planSave[0].medical3Activator;
        this.inputParam.medical3Admission = planSave[0].medical3Admission;
        this.inputParam.medical3Payment = planSave[0].medical3Payment;
        this.inputParam.medical3Course = planSave[0].medical3Course;
        this.inputParam.medical3Price = planSave[0].medical3Price;
        this.inputParam.disability3Activator = planSave[0].disability3Activator;
        this.inputParam.disability3Benefit = planSave[0].disability3Benefit;
        this.inputParam.disability3Term = planSave[0].disability3Term;
        this.inputParam.disability3Billable = planSave[0].disability3Billable;
        this.inputParam.disability3Option = planSave[0].disability3Option;
        this.inputParam.disability3Return = planSave[0].disability3Return;
        this.inputParam.disability3Price = planSave[0].disability3Price;
        this.inputParam.cancerActivator = planSave[0].cancerActivator;
        this.inputParam.cancerBenefit = planSave[0].cancerBenefit;
        this.inputParam.cancerCourse = planSave[0].cancerCourse;
        this.inputParam.cancerPrice = planSave[0].cancerPrice;
        this.inputParam.dementiaActivator = planSave[0].dementiaActivator;
        this.inputParam.dementiaCourse = planSave[0].dementiaCourse;
        this.inputParam.dementiaBenefit = planSave[0].dementiaBenefit;
        this.inputParam.dementiaPrice = planSave[0].dementiaPrice;
        this.inputParam.estimateSiteUrl = planSave[0].estimateSiteUrl;
        this.inputParam.totalPrice = planSave[0].totalPrice;
      }
    },

    confirmSave(event: { returnValue: string }) {
      event.returnValue = "行った変更が保存されない可能性があります。";
    },
  },
  computed: {
    /**
     * 見積りメール保存APIのURLを返却します。
     *
     * @returns 見積りメール保存APIのURL
     */
    planSaveApiUrl(): string {
      return ApiUrlEnv.apiBaseUrl + "/plan/save";
    },

    /**
     * 見積もりメール保存API用のAPIKEYを返却します。
     *
     * @returns 見積もりメール保存API用のAPIKEY
     */
    planSaveApiKey(): string {
      return ApiUrlEnv.apiKey;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.confirmSave);
  },
  unmounted() {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
  mounted() {
    // 静的サイト側からリダイレクトした場合の処理
    this.setInputParamFromCookie();
    this.validateEstimateSiteUrl();
    this.estimateSiteUrl = EstimateSiteUrlGenerater.createEstimateSiteUrl(
      this.inputParam
    );
  },
});
