import { InputParam } from "@/interface/lead/LeadInputInterFaces";

export class EstimateSiteUrlGenerater {
  /**
   * 静的サイトへアクセスする為のURLを作成します
   *
   * @param inputParam 見積メール保存
   * @returns
   */
  public static createEstimateSiteUrl(inputParam: InputParam): string {
    // 就業不能のURLパラメータを生成します。
    const disabilityUrlParameter: string =
      this.generateDisabilityUrlParameter(inputParam);
    // 死亡保険のURLパラメータを生成します。
    const lifeUrlParameter: string = this.generateLifeUrlParameter(inputParam);
    // がん保険のURLパラメータを生成します。
    const cancerUrlParameter: string =
      this.generateCancerUrlParameter(inputParam);
    // 医療保険のURLパラメータを生成します。
    const medicalUrlParameter: string =
      this.generateMedicalUrlParameter(inputParam);
    // 認知症保険のURLパラメータを生成します。
    const dementiaUrlParameter: string =
      this.generateDementiaUrlParameter(inputParam);

    return (
      inputParam.estimateSiteUrl +
      "?year=" +
      inputParam.birthYear +
      "&month=" +
      inputParam.birthMonth +
      "&date=" +
      inputParam.birthDay +
      "&sex=" +
      inputParam.sex +
      lifeUrlParameter +
      "&treatment-activator=FALSE" +
      disabilityUrlParameter +
      cancerUrlParameter +
      medicalUrlParameter +
      dementiaUrlParameter
    );
  }

  /**
   * 認知症保険のURLパラメータ作成を行います。
   *
   * @returns 認知症保険URLのパラメータ
   */
  private static generateDementiaUrlParameter(inputParam: InputParam) {
    // 認知症保険が有効だった場合
    if (inputParam.dementiaActivator === "TRUE") {
      return (
        "&dementia-activator=" +
        inputParam.dementiaActivator +
        "&dementia-course=" +
        inputParam.dementiaCourse +
        "&dementia-benefit=" +
        inputParam.dementiaBenefit
      );
    }
    return "&dementia-activator=FALSE";
  }

  /**
   * 就業不能保険のURLパラメータ作成を行います。
   *
   * @returns 就業不能保険URLのパラメータ
   */
  private static generateDisabilityUrlParameter(inputParam: InputParam) {
    // 就業不能保険が有効だった場合
    if (inputParam.disability3Activator === "TRUE") {
      return (
        "&disability3-activator=" +
        inputParam.disability3Activator +
        "&disability3-benefit=" +
        inputParam.disability3Benefit +
        "&disability3-term=" +
        inputParam.disability3Term +
        "&disability3-billable=" +
        inputParam.disability3Billable +
        "&disability3-option=" +
        inputParam.disability3Option +
        "&disability3-return=" +
        inputParam.disability3Return
      );
    }
    return "&disability3-activator=FALSE";
  }

  /**
   * 死亡保険のURLパラメータ作成を行います。
   *
   * @returns 死亡保険URLのパラメータ
   */
  private static generateLifeUrlParameter(inputParam: InputParam) {
    // 死亡保険が有効だった場合
    if (inputParam.lifeActivator === "TRUE") {
      return (
        "&life-activator=" +
        inputParam.lifeActivator +
        "&term=" +
        inputParam.term +
        "&insurance=" +
        inputParam.insurance
      );
    }
    return "&life-activator=FALSE";
  }

  /**
   * がん保険のURLパラメータ作成を行います。
   *
   * @returns がん保険URLのパラメータ
   */
  private static generateCancerUrlParameter(inputParam: InputParam) {
    // がん保険が有効だった場合
    if (inputParam.cancerActivator === "TRUE") {
      return (
        "&cancer-activator=" +
        inputParam.cancerActivator +
        "&cancer-benefit=" +
        inputParam.cancerBenefit +
        "&cancer-course=" +
        inputParam.cancerCourse
      );
    }
    // 未選択だった場合、選択フラグのみを作成して返す。
    return "&cancer-activator=FALSE";
  }

  /**
   * 医療保険のURLパラメータ作成を行います。
   *
   * @returns 医療保険URLのパラメータ
   */
  private static generateMedicalUrlParameter(inputParam: InputParam) {
    // 医療保険が有効だった場合
    if (inputParam.medical3Activator === "TRUE") {
      return (
        "&medical3-activator=" +
        inputParam.medical3Activator +
        "&medical3-admission=" +
        inputParam.medical3Admission +
        "&medical3-payment=" +
        inputParam.medical3Payment +
        "&medical3-course=" +
        inputParam.medical3Course
      );
    }
    // 未選択だった場合、選択フラグのみを作成して返す。
    return "&medical3-activator=FALSE";
  }
}
